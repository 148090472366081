import Sortable from "sortablejs";

document.addEventListener("turbo:load", () => {
  const sortContainer = document.querySelector("div.imagelist");
  if (sortContainer && sortContainer instanceof HTMLElement)
    Sortable.create(sortContainer, {
      ghostClass: "sortableGhost",
    });
});

let submitting = false;

const beforeFormLeaveHandler = (e) => {
  if (submitting) return true;
  if (
    !!document.querySelector(".list-preview") ||
    Array.from(document.querySelectorAll("input[type=text]:not(#post_password),textarea")).some(
      (e) => (e as HTMLFormElement).value.length > 0,
    )
  ) {
    const res = confirm(
      "Achtung, deine Anzeige wurde noch nicht gespeichert. Willst du abbrechen und deine Eingaben löschen (Button ABBRECHEN) oder deine Anzeige weiter bearbeiten (Button OK)?",
    );
    if (res) {
      e.preventDefault();
    }
    return res;
  }
  return true;
};

document.addEventListener("turbo:load", () => {
  submitting = false;
  document.querySelectorAll("form[data-warn-leaving-when-changed]").forEach((form) => {
    form.addEventListener("turbo:submit-start", () => (submitting = true));
    window.addEventListener("turbo:before-visit", beforeFormLeaveHandler);
  });
});
